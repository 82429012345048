<template>
  <view-card-template :loaded="loaded">

    <template #cardTitle>
      Settings
    </template>

    <template #cardBody>
      <v-container
        class="px-0 py-4 d-flex flex-column justify-space-between"
        style="height: 100%;"
      >

        <!-- START Setting Sections -->
        <div>
          <!-- Section Header -->
          <v-row
            class="px-6 py-3 flex-grow-0"
            no-gutters
          >
            <v-col cols="9">
              <h3>Nominations</h3>
            </v-col>
            <v-spacer/>
          </v-row>

          <!-- Nominations -->
          <v-row
            class="px-6 py-2 flex-grow-0"
            style="background-color: #eee"
            no-gutters
          >
            <v-col cols="9">
              <h4>Require Choose Player</h4>
              <p class="text-subtitle-2 font-weight-light">Require that a player be chosen from the player dropdown for all nominations</p>
            </v-col>
            <v-spacer/>
            <v-col cols="3">
              <div
                style="height: 100%;"
                class="d-flex justify-center align-center"
              >
                <v-checkbox
                  v-model="settings.choosePlayerIsRequired"
                  class="pa-0 ma-0"
                  hide-details
                />
              </div>
            </v-col>
          </v-row>
        </div>
        <!-- END Setting Sections -->

        <!-- START Page Actions -->
        <v-card-actions style="margin-top: auto;">
          <v-spacer/>
          <v-btn
            @click="navigateBack"
            depressed
          >
            Cancel
          </v-btn>
          <v-btn
            @click="saveSettings()"
            :loading="saveSettingsLoading"
            color="primary"
          >
            Save
          </v-btn>
        </v-card-actions>
        <!-- END Page Actions -->

      </v-container>
    </template>
  </view-card-template>
</template>

<script>
import ViewCardTemplate from '@/components/templates/ViewCardTemplate.vue'

import Controllers from "@/data/controllers"

export default {
  name: "AdminSettings",

  components: {
    ViewCardTemplate,
  },

  props:  {
    //
  },

  data: () => ({
    loaded: false,
    saveSettingsLoading: false,

    settings: null,
  }),

  computed: {
    //
  },

  methods: {
    navigateBack() {
      this.$router.push({ name: "AwardManagement" })
    },

    async saveSettings() {
      this.saveSettingsLoading = true

      const res = await Controllers.ConferenceSettingController.AdminUpdateConferenceSettings(this.settings)

      if(res && !res.hasError) {
        this.$root.showSnackbar('Settings saved successfully!', "success", 5000)
        this.$router.push({ name: "AwardManagement" })
      }
      else {
        // Error handling
        this.$root.showSnackbar('There an error saving settings.', "error", 5000)
      }

      this.saveSettingsLoading = false
    },

  },

  async created() {
    this.$root.updateTitle("Settings")

    const res = await Controllers.ConferenceSettingController.GetConferenceSettings()

    if(res && !res.hasError) {
      const settings = res.data

      this.settings = settings
    }
    else {
      // Error handling
    }

    this.loaded = true
  }
}
</script>

<style>

</style>